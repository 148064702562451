<template>
  <v-container class="fill-height align-start">
    <template v-if="loading">
      <v-row class="align-self-center" justify="center" align="center">
        <v-progress-circular color="primary" indeterminate />
      </v-row>
    </template>

    <template v-else>
      <v-row justify="center" no-gutters>
        <v-col cols="12" lg="10" xl="9">
          <template v-if="streaming">
            <video-player
              ref="player"
              :initial-source="source"
              :poster="poster"
              :language="locale"
              :initial-volume="0.5"
              :playback-rates="[0.5, 0.75, 1, 1.25, 1.5]"
              preload="auto"
              autoplay
              liveui
              big-play-centered
              show-big-play-on-pause
              @ready="handleReadyEvent"
              @loadstart="handleLoadStart"
              @status="handleStatusEvent"
              @service="handleServiceEvent"
              @fullscreenchange="handleFullScreenEvent"
              @timeupdate="handleTimeUpdateEvent"
              @firstplay="handleFirstPlay"
              @liveedgechange="handleLiveEdgeChange"
            >
              <template v-slot:default="{ live, fullscreen }">
                <reaction-live
                  v-if="!isMobile"
                  ref="reactions"
                  :fullscreen="fullscreen"
                  :live="live"
                />
              </template>

              <template v-slot:fullscreen="{ live }">
                <reaction-buttons-live
                  v-if="!isMobile"
                  :disabled="!live"
                  @reaction-send="handleReactionSend"
                />
              </template>
            </video-player>
          </template>
          <template v-else>
            <stream-poster
              :poster="poster"
              :finished="finished"
              :knowledge-base-link="knowledgeBaseLink"
              :uploaded="uploaded"
              :start-date="startDate"
              :unavailable="!source"
            />
          </template>

          <video-details class="mt-3 mt-sm-5" :title="title" :date="startDate" :divider="false">
            <template v-slot:append v-if="streaming">
              <v-tooltip :disabled="live" bottom open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <reaction-buttons
                    v-if="!isMobile"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!live"
                    @reaction-send="handleReactionSend"
                  />
                </template>
                <span>{{ $t('views.stream.tooltip.reactions') }}</span>
              </v-tooltip>
            </template>
          </video-details>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import VideoPlayer from '@/components/common/VideoPlayer.vue';
import VideoDetails from '@/components/common/VideoDetails.vue';

import StreamPoster from '@/components/stream/StreamPoster.vue';
import ReactionLive from '@/components/stream/ReactionLive.vue';
import ReactionButtons from '@/components/stream/ReactionButtons.vue';
import ReactionButtonsLive from '@/components/stream/ReactionButtonsLive.vue';

import Settings from '@/api/http/static/settings';
import Translation from '@/api/http/translation';

import Socket from '@/api/socket/default';

import LocaleMixin from '@/mixins/locale';
import MediaMixin from '@/mixins/media';
import PlayerMixin from '@/mixins/player';

import { IS_MOBILE } from '@/helpers/browser';
import { copyValues } from '@/helpers/objects';

import { STATUS_ROUTES } from '@/constants/router';
import { CHECK_SETTINGS_INTERVAL, CHECK_TRANSLATION_INTERVAL } from '@/constants/stream';

export default {
  metaInfo() {
    return {
      title: this.title || this.$t('views.stream.title'),
    };
  },
  data() {
    return {
      loading: true,
      player: {
        liveEdge: false,
        started: false,
      },
      settings: {
        id: null,
        active: null,
        finished: null,
        knowledgeBaseLink: null,
        startDate: null,
        title: null,
        playlist: null,
        uploaded: null,
        poster: null,
      },
      translation: null,
      timeout: {
        settings: null,
        translation: null,
      },
      isMobile: IS_MOBILE,
    };
  },
  computed: {
    active() {
      return !!this.settings.active;
    },
    finished() {
      return !!this.settings.finished;
    },
    uploaded() {
      return !!this.settings.uploaded;
    },
    knowledgeBaseLink() {
      const { knowledgeBaseLink } = this.settings;

      return knowledgeBaseLink && knowledgeBaseLink[this.locale];
    },
    startDate() {
      return this.settings.startDate;
    },
    title() {
      const { title } = this.settings;

      return title && title[this.locale];
    },
    playlist() {
      const { playlist } = this.settings;

      return playlist && playlist[this.locale];
    },
    poster() {
      return this.settings.poster;
    },
    source() {
      return {
        src: this.playlist,
        type: 'application/x-mpegURL',
      };
    },
    streaming() {
      return !!this.playlist && !!this.translation && this.active && !this.finished;
    },
    live() {
      const { started, liveEdge } = this.player;

      return started && liveEdge;
    },
  },
  mounted() {
    this.initialFetch().catch(() => {
      this.$toast.error(this.$t('views.stream.error.settings'));
    });

    this.checkSettings();
    this.checkTranslation();

    Socket.connect();
  },
  beforeDestroy() {
    this.clearTimeouts();

    Socket.disconnect();
  },
  watch: {
    streaming() {
      this.streamingChanged();
    },
  },
  methods: {
    async initialFetch() {
      this.loading = true;

      const { id } = await this.refreshSettings();

      if (!id) {
        return this.$router.push({ name: 'notFound' });
      }

      return this.refreshTranslation()
        .then(() => this.streaming && this.refreshCredentials(this.playlist))
        .finally(() => {
          this.loading = false;
        });
    },
    checkSettings() {
      clearTimeout(this.timeout.settings);

      this.timeout.settings = setTimeout(() => {
        this.refreshSettings()
          .catch(error => error)
          .finally(() => this.checkSettings());
      }, CHECK_SETTINGS_INTERVAL);
    },
    checkTranslation() {
      clearTimeout(this.timeout.translation);

      this.timeout.translation = setTimeout(() => {
        this.refreshTranslation()
          .catch(error => error)
          .finally(() => this.checkTranslation());
      }, CHECK_TRANSLATION_INTERVAL);
    },
    async refreshSettings() {
      return Settings.get().then(({ data }) => this.parseSettings(data));
    },
    async refreshTranslation() {
      return Translation.get(this.settings.id)
        .then(({ data }) => {
          this.translation = data;
        })
        .catch(({ status }) => {
          const name = STATUS_ROUTES[status];

          if (!name) {
            return;
          }

          return this.$router.push({ name });
        });
    },
    parseSettings(settings = {}) {
      const source = copyValues(this.settings, settings);

      return Object.assign(this.settings, source, {
        startDate: source.startDate && new Date(source.startDate),
      });
    },
    handleReactionSend(type) {
      if (!this.isMobile) {
        this.$refs.reactions.manualReaction(type);
      }
    },
    streamingChanged() {
      if (!this.streaming || this.loading) {
        return;
      }

      this.loading = true;

      this.refreshCredentials(this.playlist).finally(() => {
        this.loading = false;
      });
    },
    handleFirstPlay() {
      this.player.started = true;
    },
    handleLiveEdgeChange(liveEdge) {
      this.player.liveEdge = liveEdge;
    },
    handleReadyEvent() {
      this.trackPlayerEvent('player_ready');
    },
    handleLoadStart(source) {
      this.trackPlayerEvent('player_source', { locale: this.locale });
      this.trackPlayerStateEvent('loadstart', { source });
    },
    handleStatusEvent({ type }) {
      const time = this.$refs.player.currentTime();

      this.trackPlayerStateEvent(type, { time });
    },
    handleServiceEvent({ type }) {
      this.trackPlayerStateEvent(type);
    },
    handleFullScreenEvent(full) {
      this.trackPlayerStateEvent('fullscreen', { full });
    },
    handleTimeUpdateEvent(progress) {
      if (!this.validateProgress(progress)) {
        return;
      }

      this.trackPlayerEvent('player_progress', { minutes: this.minutes });
      this.trackStatisticEvent('progress', { minutes: this.minutes });
    },
    clearTimeouts() {
      Object.values(this.timeout).forEach(clearTimeout);
    },
  },
  components: {
    VideoPlayer,
    VideoDetails,
    StreamPoster,
    ReactionButtons,
    ReactionButtonsLive,
    ReactionLive,
  },
  mixins: [LocaleMixin, MediaMixin, PlayerMixin],
};
</script>
