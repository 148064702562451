<template>
  <v-img :src="poster" :aspect-ratio="16 / 9">
    <base-overlay class="align-end justify-start px-4" dark>
      <v-alert v-if="finished" dark icon="video_library">
        <i18n :path="finishedMessagePath">
          <template v-slot:link>
            <i18n
              path="components.poster.finished.link"
              tag="a"
              target="_blank"
              :href="knowledgeBaseLink"
            ></i18n>
          </template>
        </i18n>
      </v-alert>
      <v-alert v-else-if="unavailable && startDate" dark icon="videocam_off">
        {{ $t('components.poster.source') }}
      </v-alert>
      <v-alert v-else-if="startDate" dark icon="event">
        <i18n path="components.poster.start.date">
          <template v-slot:date>{{ $d(startDate, 'long') }}</template>
        </i18n>
      </v-alert>
      <v-alert v-else dark icon="schedule">
        {{ $t('components.poster.start.scheduled') }}
      </v-alert>
    </base-overlay>
  </v-img>
</template>

<script>
import BaseOverlay from '@/components/base/BaseOverlay.vue';

export default {
  props: {
    poster: {
      type: String,
      required: true,
    },
    finished: {
      type: Boolean,
    },
    knowledgeBaseLink: {
      type: String,
    },
    uploaded: {
      type: Boolean,
    },
    unavailable: {
      type: Boolean,
    },
    startDate: {
      type: Date,
    },
  },
  components: { BaseOverlay },

  computed: {
    finishedMessagePath() {
      return this.uploaded
        ? 'components.poster.uploaded.message'
        : 'components.poster.finished.message';
    },
  },
};
</script>
