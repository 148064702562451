import urlcat from 'urlcat';

import { createClient } from '../../client';

import { API_BASE_URL_STATIC } from '../../config';
import { SETTINGS } from '../../config/endpoints';

const client = createClient(urlcat(API_BASE_URL_STATIC, SETTINGS));

export default class Settings {
  static async get() {
    const params = {
      t: Date.now(),
    };

    return client.get('', { params });
  }
}
