const USER_AGENT = (window.navigator && window.navigator.userAgent) || '';

const TOUCH_ENABLED = 'ontouchstart' in window || !!window.navigator.maxTouchPoints;

const IS_IOS = /iP(hone|od|ad)/.test(USER_AGENT);
const IS_IPAD_OS = /Mac/.test(USER_AGENT) && TOUCH_ENABLED;

const IS_ANDROID = /Android/i.test(USER_AGENT);

const IS_MOBILE = IS_IOS || IS_IPAD_OS || IS_ANDROID;

const IS_EDGE = /Edg/i.test(USER_AGENT);

const IS_CHROME = !IS_EDGE && (/Chrome/i.test(USER_AGENT) || /CriOS/i.test(USER_AGENT));

const IS_SAFARI = /Safari/i.test(USER_AGENT) && !IS_CHROME && !IS_ANDROID && !IS_EDGE;
const IS_ANY_SAFARI = (IS_SAFARI || IS_IOS) && !IS_CHROME;

export {
  TOUCH_ENABLED,
  IS_IOS,
  IS_IPAD_OS,
  IS_ANDROID,
  IS_MOBILE,
  IS_EDGE,
  IS_CHROME,
  IS_SAFARI,
  IS_ANY_SAFARI,
};
