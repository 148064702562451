<template>
  <transition v-on:enter="enter" v-on:appear="enter" v-bind:css="false" appear>
    <div :class="$style.entity">
      <emoji-icon :icon="icon" :size="40"></emoji-icon>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap';

import EmojiIcon from '@/components/icons/EmojiIcon.vue';

import { REACTION_ICONS } from '@/constants/reaction';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    icon() {
      return REACTION_ICONS[this.type];
    },
  },
  methods: {
    enter(el, done) {
      gsap.fromTo(
        el,
        {
          opacity: 1,
          scale: 0.7,
        },
        {
          opacity: 0,
          scale: 'random(1, 2)',
          x: 'random(-10, 10)',
          y: '-=250',
          duration: 'random(3, 5)',
          delay: this.delay / 1000,
          immediateRender: false,
          modifiers: {
            x: x => `${Math.sin(parseFloat(x)) * 20}px`,
          },
          onComplete: () => {
            done();

            this.$emit('entered');
          },
        }
      );
    },
  },
  components: { EmojiIcon },
};
</script>

<style lang="scss" module>
.entity {
  position: absolute;
  opacity: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
}
</style>
