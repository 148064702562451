<template>
  <button :class="classes" :disabled="disabled" v-on="$listeners">
    <slot name="default"></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      const { root, 'root--disabled': disabled } = this.$style;

      return {
        [root]: true,
        [disabled]: this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" module>
button.root {
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  touch-action: manipulation;
  -webkit-appearance: none;
  appearance: none;
  will-change: transform;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.25);
  }

  &:active {
    transform: scale(0.9);
  }

  &--disabled {
    pointer-events: none;
    user-select: none;
  }
}
</style>
