<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      const { isDark } = this.$vuetify.theme;

      return {
        overlay: true,
        'overlay--dark': isDark || this.dark,
      };
    },
  },
};
</script>
