<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    role="presentation"
    :aria-labelledby="icon"
    :width="size"
    :height="size"
    :style="styles"
  >
    <use :xlink:href="`#${symbolId}`" />
  </svg>
</template>

<script>
import u1f44d from '@/assets/icons/emoji/emoji_u1f44d.svg';
import u1f525 from '@/assets/icons/emoji/emoji_u1f525.svg';
import u1f632 from '@/assets/icons/emoji/emoji_u1f632.svg';
import u1f641 from '@/assets/icons/emoji/emoji_u1f641.svg';
import u1f923 from '@/assets/icons/emoji/emoji_u1f923.svg';
import u1f973 from '@/assets/icons/emoji/emoji_u1f973.svg';
import u1f49a from '@/assets/icons/emoji/emoji_u1f49a.svg';

const ICON_COMPONENTS = {
  astonished: u1f632.id,
  fire: u1f525.id,
  green_heart: u1f49a.id,
  partying: u1f973.id,
  rolling_laughing: u1f923.id,
  slightly_frowning: u1f641.id,
  thumbs_up: u1f44d.id,
};

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 18,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    styles() {
      return {
        opacity: this.disabled ? 0.3 : 1,
      };
    },
    symbolId() {
      return ICON_COMPONENTS[this.icon];
    },
  },
};
</script>
