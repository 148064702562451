const Reaction = {
  ASTONISHED: 'astonished',
  FIRE: 'fire',
  HEART: 'heart',
  PARTYING: 'partying',
  LAUGHING: 'laughing',
  FROWNING: 'frowning',
  LIKE: 'like',
};

const REACTION_ICONS = {
  [Reaction.LIKE]: 'thumbs_up',
  [Reaction.FIRE]: 'fire',
  [Reaction.HEART]: 'green_heart',
  [Reaction.PARTYING]: 'partying',
  [Reaction.LAUGHING]: 'rolling_laughing',
  [Reaction.ASTONISHED]: 'astonished',
  [Reaction.FROWNING]: 'slightly_frowning',
};

const RENDER_LIMIT = 20;
const RENDER_DELAY = 250;

export { Reaction, REACTION_ICONS, RENDER_LIMIT, RENDER_DELAY };
