var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height align-start"},[(_vm.loading)?[_c('v-row',{staticClass:"align-self-center",attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)]:[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"10","xl":"9"}},[(_vm.streaming)?[_c('video-player',{ref:"player",attrs:{"initial-source":_vm.source,"poster":_vm.poster,"language":_vm.locale,"initial-volume":0.5,"playback-rates":[0.5, 0.75, 1, 1.25, 1.5],"preload":"auto","autoplay":"","liveui":"","big-play-centered":"","show-big-play-on-pause":""},on:{"ready":_vm.handleReadyEvent,"loadstart":_vm.handleLoadStart,"status":_vm.handleStatusEvent,"service":_vm.handleServiceEvent,"fullscreenchange":_vm.handleFullScreenEvent,"timeupdate":_vm.handleTimeUpdateEvent,"firstplay":_vm.handleFirstPlay,"liveedgechange":_vm.handleLiveEdgeChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var live = ref.live;
var fullscreen = ref.fullscreen;
return [(!_vm.isMobile)?_c('reaction-live',{ref:"reactions",attrs:{"fullscreen":fullscreen,"live":live}}):_vm._e()]}},{key:"fullscreen",fn:function(ref){
var live = ref.live;
return [(!_vm.isMobile)?_c('reaction-buttons-live',{attrs:{"disabled":!live},on:{"reaction-send":_vm.handleReactionSend}}):_vm._e()]}}],null,false,2383779270)})]:[_c('stream-poster',{attrs:{"poster":_vm.poster,"finished":_vm.finished,"knowledge-base-link":_vm.knowledgeBaseLink,"uploaded":_vm.uploaded,"start-date":_vm.startDate,"unavailable":!_vm.source}})],_c('video-details',{staticClass:"mt-3 mt-sm-5",attrs:{"title":_vm.title,"date":_vm.startDate,"divider":false},scopedSlots:_vm._u([(_vm.streaming)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"disabled":_vm.live,"bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isMobile)?_c('reaction-buttons',_vm._g(_vm._b({attrs:{"disabled":!_vm.live},on:{"reaction-send":_vm.handleReactionSend}},'reaction-buttons',attrs,false),on)):_vm._e()]}}],null,false,3325110420)},[_c('span',[_vm._v(_vm._s(_vm.$t('views.stream.tooltip.reactions')))])])]},proxy:true}:null],null,true)})],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }