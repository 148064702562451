<template>
  <div v-on="$listeners">
    <template v-for="(icon, type) in reactions">
      <reaction-button
        :class="$style.button"
        :key="type"
        :disabled="inactive"
        @click="handleReactionClick(type)"
      >
        <emoji-icon :icon="icon" :size="size" :disabled="inactive" />
      </reaction-button>
    </template>
  </div>
</template>

<script>
import Socket from '@/api/socket/default';

import ReactionButton from '@/components/stream/ReactionButton.vue';
import EmojiIcon from '@/components/icons/EmojiIcon.vue';

import { REACTION_ICONS } from '@/constants/reaction';

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 26,
    },
  },
  data() {
    return {
      connected: false,
    };
  },
  computed: {
    reactions: () => REACTION_ICONS,
    inactive() {
      return this.disabled || !this.connected;
    },
  },
  mounted() {
    this.setupSocketEvents();

    this.connected = Socket.connected;
  },
  beforeDestroy() {
    this.removeSocketEvents();
  },
  methods: {
    setupSocketEvents() {
      Socket.on('connect', this.handleSocketConnect);
      Socket.on('disconnect', this.handleSocketConnect);
    },
    removeSocketEvents() {
      Socket.off('connect', this.handleSocketConnect);
      Socket.off('disconnect', this.handleSocketConnect);
    },
    handleSocketConnect() {
      this.connected = true;
    },
    handleSocketDisconnect() {
      this.connected = false;
    },
    handleReactionClick(type) {
      this.$emit('reaction-send', type);

      Socket.emit('reaction:send', type);
    },
  },
  components: { ReactionButton, EmojiIcon },
};
</script>

<style lang="scss" module>
.button {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
}
</style>
