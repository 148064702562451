import { io } from 'socket.io-client';

import { SOCKET_URL } from '@/api/config';
import AuthService from '@lib/vue-playrix-auth/service';

const Socket = io(SOCKET_URL, {
  autoConnect: false,
  transports: ['websocket'],
  auth: cb =>
    cb({
      token: AuthService.getToken(),
    }),
});

export default Socket;
