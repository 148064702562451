<template>
  <div :class="classes">
    <template v-for="{ id, priority, type } in active">
      <reaction-live-entity
        :key="id"
        :type="type"
        :delay="priority * delay"
        @entered="handleEntityEntered(id)"
      />
    </template>
  </div>
</template>

<script>
import shuffle from 'lodash/shuffle';

import ReactionLiveEntity from '@/components/stream/ReactionLiveEntity.vue';

import Socket from '@/api/socket/default';

import { RENDER_LIMIT, RENDER_DELAY } from '@/constants/reaction';

function buildRandomId() {
  return Math.round(Date.now() * Math.random());
}

export default {
  props: {
    live: {
      type: Boolean,
    },
    fullscreen: {
      type: Boolean,
    },
  },
  data() {
    return {
      active: [],
      queue: [],
    };
  },
  computed: {
    limit: () => RENDER_LIMIT,
    delay: () => RENDER_DELAY,
    classes() {
      const { wrapper, 'wrapper--full': full } = this.$style;

      return {
        [wrapper]: true,
        [full]: this.fullscreen,
      };
    },
  },
  watch: {
    live() {
      this.liveChanged();
    },
  },
  mounted() {
    if (!this.live) {
      return;
    }

    this.setupHandlers();
  },
  beforeDestroy() {
    this.removeHandlers();
  },
  methods: {
    liveChanged() {
      if (this.live) {
        this.setupHandlers();

        return;
      }

      this.removeHandlers();

      this.clearQueue();
    },
    setupHandlers() {
      Socket.on('reaction:live', this.handleReactionLive);
    },
    removeHandlers() {
      Socket.off('reaction:live', this.handleReactionLive);
    },
    handleReactionLive(types) {
      const reactions = this.transformReactions(types);

      this.enqueueReactions(reactions);
    },
    transformReactions(types) {
      const total = types.reduce((sum, curr) => sum + curr[1], 0);

      const transformed = types.flatMap(([type, value]) => {
        let count = value;

        if (total > this.limit) {
          count = Math.round((value / total) * this.limit);
        }

        const id = buildRandomId();

        return Array.from(Array(count), (v, index) => ({
          type,
          id: id + index,
          priority: 0,
        }));
      });

      return shuffle(transformed);
    },
    manualReaction(type) {
      this.active.push({
        type,
        id: buildRandomId(),
        priority: 0,
      });
    },
    enqueueReactions(reactions = []) {
      this.queue.push(...reactions);

      this.processQueue();
    },
    processQueue() {
      const count = this.limit - this.active.length;

      if (count < 1) {
        return;
      }

      const active = this.queue
        .splice(0, count)
        .map((el, priority) => Object.assign(el, { priority }));

      this.active.push(...active);
    },
    clearQueue() {
      this.queue.splice(0, this.queue.length);
    },
    handleEntityEntered(id) {
      const index = this.active.findIndex(({ id: search }) => search === id);

      this.$delete(this.active, index);

      this.processQueue();
    },
  },
  components: { ReactionLiveEntity },
};
</script>

<style lang="scss" module>
.wrapper {
  position: absolute;
  right: 40px;
  bottom: 30px;

  &--full {
    bottom: 55px;
    right: 50px;
  }
}
</style>
